import type { CotEvent } from "@kha/cot";
import { z } from "zod";

export type Base64 = string;

const udpConnectionConfiguration = z.object({
  type: z.literal("udp"),
  port: z.number()
});

export type UdpConnectionConfiguration = z.infer<
  typeof udpConnectionConfiguration
>;

const websocketConnectionConfiguration = z.object({
  type: z.literal("websocket"),
  url: z.string()
});

export type WebSocketConnectionConfiguration = z.infer<
  typeof websocketConnectionConfiguration
>;

const serialConnectionConfiguration = z.object({
  type: z.literal("serial"),
  baudRate: z.number(),
  path: z.string()
});

export type SerialConnectionConfiguration = z.infer<
  typeof serialConnectionConfiguration
>;

export type LogConnectionConfiguration = {
  type: "log";
  file: File;
};

export const savedConnectionConfiguration = z.union([
  udpConnectionConfiguration,
  websocketConnectionConfiguration,
  serialConnectionConfiguration
]);

export type SavedConnectionConfiguration = z.infer<
  typeof savedConnectionConfiguration
>;

export type ConnectionConfiguration =
  | SavedConnectionConfiguration
  | { type: "log"; file: File };

export const videoConfiguration = z.object({
  host: z.string(),
  port: z.number()
});

export type VideoConfiguration = z.infer<typeof videoConfiguration>;

export type Time = number;

export const point = z.tuple([
  z.number(), // WGS84 decimal degrees -180 to 180
  z.number() // WGS84 decimal degrees -90 to 90
]);

export type Point = z.infer<typeof point>;

const position = z.tuple([
  z.number(), // WGS84 decimal degrees -180 to 180
  z.number(), // WGS84 decimal degrees -90 to 90
  z.number() // MSL - height above mean sea level in meters
]);

export type Position = z.infer<typeof position>;

const vec3 = z.tuple([z.number(), z.number(), z.number()]);

export type Vec3 = z.infer<typeof vec3>;

export type Orientation = [pitch: number, yaw: number, roll: number];

export const view = z.object({
  target: vec3,
  offset: z.tuple([z.number(), z.number()]),
  distance: z.number(),
  orientation: vec3,
  fieldOfView: z.number()
});

export const cueType = z.enum(["photo", "video"]);

export type CueType = z.infer<typeof cueType>;

export type SwarmStatus =
  | "excellent"
  | "good"
  | "minimum"
  | "insufficient"
  | "unknown";

export type Parameters = {
  loiterRadius: number;
  targetAirspeed: number;
  minAirspeed: number;
  maxAirspeed: number;
  landAirspeed: number;
  landSinkRate: number;
  landFlareAltitude: number;
  takeoffRotateSpeed: number;
  maxThrottle: number;
  climbRate: number;
  sinkRate: number;
  altitudeDemandTracking: number;
  altitudeSpeedRatio: number;
  failsafeTimeout: number;
  failsafeAltitude: number;
};

export type AdsbSettings = {
  enabled: boolean;
  squawk: number;
  flightId: string;
};

export const relayNames = [
  "GPIO A",
  "GPIO B",
  "GPIO RADIO",
  "GPIO C",
  "GPIO D",
  "POWER A",
  "POWER B",
  "POWER RADIO",
  "POWER C",
  "POWER D",
  "POWER AUX",
  "POWER LIGHTS"
] as const;

export type Relay = (typeof relayNames)[number];

export type State = {
  id: number;
  system: number;
  time: Time;
  serial: number;
  connected: boolean;
  packetLoss: number;
  version: string;
  khaVersion: string;
  hash: string;
  timeBoot: number;
  load: number;
  vtol: boolean;
  mode: string;
  swarmable: boolean;
  swarming: boolean;
  home: Position;
  position: Position;
  orientation: Orientation;
  armed: boolean;
  preArmFailures: string[];
  flying: boolean;
  critical: boolean;
  landing: boolean;
  failsafe: string;
  flightTime: number;
  eta: number | undefined;
  climbEta: number | undefined;
  battery: number | undefined;
  batteryVoltage: number;
  batteryTimeRemaining: number;
  batteryCapacity: number;
  batteryTemperature?: number;
  power: number;
  mppt1: number;
  mppt2: number;
  mppt3: number;
  mppt4: number;
  vtolBatteryFlVoltage: number;
  vtolBatteryFrVoltage: number;
  vtolBatteryBlVoltage: number;
  vtolBatteryBrVoltage: number;
  vtolBatteryFlCurrent: number;
  vtolBatteryFrCurrent: number;
  vtolBatteryBlCurrent: number;
  vtolBatteryBrCurrent: number;
  vtolEscFlTemperature: number;
  vtolEscFrTemperature: number;
  vtolEscBlTemperature: number;
  vtolEscBrTemperature: number;
  ampriusVoltages: number[];
  throttle: number;
  airspeed: number;
  targetAirspeed: number;
  groundspeed: number;
  targetAltitude: number;
  climb: number;
  windSpeed: number;
  windDirection: number;
  pressure1: number;
  pressure2: number;
  barometerTemperature1: number;
  barometerTemperature2: number;
  groundPressure1: number;
  groundPressure2: number;
  groundStationLaunchPressure: number;
  barometerAltitudeOffset: number;
  motorTemperature: number;
  gpsSatellitesVisible: number;
  gpsFixType: string;
  target?: Position;
  targetOrientation: Orientation;
  rangefinderDistance: number;
  rangefinderTime: number;
  parameters: Parameters;
  mission: Mission;
  waypointIndex: number;
  unhealthySensors: string[];
  adsbSettings?: AdsbSettings;
  adsbVehicles: AdsbVehicle[];
  swarmVehicles: SwarmVehicle[];
  swarmRegion: Point[];
  swarmAltitude: number;
  swarmStatus: SwarmStatus;
  simulation: boolean;
  simSpeedup: boolean;
  simGpsDisable: boolean;
  simBaroDisable: boolean;
  simHomeThermal: boolean;
  simWindSpeed: number;
  simWindDirection: number;
  terminated: boolean;
  fenceRegion: Point[];
  photos: Photo[];
  ekfIssues: string[];
  lastAltitudeCalibration: number;
  lastLoiterRadius: number;
  lastAirspeed: number;
  endurance?: {
    measuredPower: number;
    measuredAuxPower: number;
    measuredAirspeed: number;
    measuredMass: number;
  };
  compassCalibrationDiagonal: Vec3;
  compassCalibrationOffDiagonal: Vec3;
  ampriusCellSelection: number;
  maimEnabled: boolean;
  avionicsTrimX: number;
  avionicsTrimY: number;
  relays: { [relay in Relay]?: boolean };
  takeoffWeight: number;
  wingspan: 5 | 6;
};

export const defaultState: State = {
  id: 0,
  system: 0,
  time: 0,
  serial: 0,
  connected: false,
  packetLoss: 0,
  version: "",
  khaVersion: "",
  hash: "",
  timeBoot: 0,
  load: 0,
  vtol: false,
  mode: "",
  swarmable: false,
  swarming: false,
  position: [0, 0, 0],
  home: [0, 0, 0],
  orientation: [0, 0, 0],
  armed: false,
  preArmFailures: [],
  flying: false,
  critical: false,
  landing: false,
  failsafe: "",
  flightTime: 0,
  eta: undefined,
  climbEta: undefined,
  battery: undefined,
  batteryVoltage: 0,
  batteryTimeRemaining: 0,
  batteryCapacity: 0,
  power: 0,
  mppt1: 0,
  mppt2: 0,
  mppt3: 0,
  mppt4: 0,
  vtolBatteryFlVoltage: 0,
  vtolBatteryFrVoltage: 0,
  vtolBatteryBlVoltage: 0,
  vtolBatteryBrVoltage: 0,
  vtolBatteryFlCurrent: 0,
  vtolBatteryFrCurrent: 0,
  vtolBatteryBlCurrent: 0,
  vtolBatteryBrCurrent: 0,
  vtolEscFlTemperature: 0,
  vtolEscFrTemperature: 0,
  vtolEscBlTemperature: 0,
  vtolEscBrTemperature: 0,
  ampriusVoltages: [],
  throttle: 0,
  airspeed: 0,
  targetAirspeed: 0,
  groundspeed: 0,
  targetAltitude: 0,
  climb: 0,
  windSpeed: 0,
  windDirection: 0,
  pressure1: 0,
  pressure2: 0,
  barometerTemperature1: 0,
  barometerTemperature2: 0,
  groundPressure1: 0,
  groundPressure2: 0,
  groundStationLaunchPressure: 0,
  barometerAltitudeOffset: 0,
  motorTemperature: 0,
  gpsSatellitesVisible: 0,
  gpsFixType: "",
  targetOrientation: [0, 0, 0],
  rangefinderDistance: 0,
  rangefinderTime: 0,
  parameters: {
    loiterRadius: 0,
    targetAirspeed: 0,
    minAirspeed: 0,
    maxAirspeed: 0,
    landAirspeed: 0,
    landSinkRate: 0,
    landFlareAltitude: 0,
    takeoffRotateSpeed: 0,
    maxThrottle: 0,
    climbRate: 0,
    sinkRate: 0,
    altitudeDemandTracking: 0,
    altitudeSpeedRatio: 0,
    failsafeTimeout: 0,
    failsafeAltitude: 0
  },
  waypointIndex: 0,
  mission: { waypoints: [] },
  unhealthySensors: [],
  adsbVehicles: [],
  swarmVehicles: [],
  swarmRegion: [],
  swarmAltitude: 0,
  swarmStatus: "unknown",
  simulation: false,
  simSpeedup: false,
  simGpsDisable: false,
  simBaroDisable: false,
  simHomeThermal: false,
  simWindSpeed: 0,
  simWindDirection: 0,
  terminated: false,
  fenceRegion: [],
  photos: [],
  ekfIssues: [],
  lastAltitudeCalibration: 0,
  lastLoiterRadius: 0,
  lastAirspeed: 0,
  compassCalibrationDiagonal: [0, 0, 0],
  compassCalibrationOffDiagonal: [0, 0, 0],
  ampriusCellSelection: 0,
  maimEnabled: false,
  avionicsTrimX: 0,
  avionicsTrimY: 0,
  relays: {},
  takeoffWeight: 0,
  wingspan: 5
};

export type GroundStationState = {
  id: number;
  system: number;
  time: Time;
  connected: boolean;
  pressure1: number;
  pressure2: number;
  barometerTemperature1: number;
  barometerTemperature2: number;
  position: Position;
  adsbVehicles: AdsbVehicle[];
};

export const runway = z.object({
  name: z.optional(z.string()),
  start: position,
  end: position,
  loiter: position,
  offset: z.number(),
  surveyed: z.boolean(),
  bidirectional: z.optional(z.boolean()),
  vtolTakeoffAltitude: z.optional(z.number()),
  vtolLandingAltitude: z.optional(z.number()),
  vtolLandingDistance: z.optional(z.number()),
  finalBearingOffset: z.optional(z.number()),
  reverseFinalBearingOffset: z.optional(z.number())
});

export type Runway = z.infer<typeof runway>;

export type TacticalLanding = {
  target: Position;
  loiter: Position;
  counterclockwise: boolean;
  finalLoiterAltitude: number;
  landingAltitude: number;
  landingDistance: number;
};

export type UnknownWaypoint = {
  type: "unknown";
};

export type TakeoffWaypoint = {
  type: "takeoff";
  altitude: number;
  relative?: boolean;
};

export type VtolTakeoffWaypoint = {
  type: "vtol takeoff";
  altitude: number;
  relative?: boolean;
};

export type BasePositionWaypoint = {
  position: Position;
  relative?: boolean;
};

export type HomeWaypoint = {
  position: Position;
  type: "home";
};

export type SimpleWaypoint = {
  type: "waypoint";
  acceptanceRadius?: number;
} & BasePositionWaypoint;

export type BaseLoiterWaypoint = {
  radius?: number;
  counterclockwise: boolean;
} & BasePositionWaypoint;

export type LoiterWaypoint = {
  type: "loiter";
} & BaseLoiterWaypoint;

export type LoiterTurnsWaypoint = {
  type: "loiter turns";
  turns: number;
  tangentExit: boolean;
} & BaseLoiterWaypoint;

export type LoiterTimeWaypoint = {
  type: "loiter time";
  seconds: number;
  counterclockwise: boolean;
  tangentExit: boolean;
} & BasePositionWaypoint;

export type LoiterToAltitudeWaypoint = {
  type: "loiter to altitude";
  tangentExit: boolean;
} & BaseLoiterWaypoint;

export type LandStartWaypoint = {
  type: "land start";
} & BasePositionWaypoint;

export type LandWaypoint = {
  type: "land";
} & BasePositionWaypoint;

export type VtolLandWaypoint = {
  type: "vtol land";
} & BasePositionWaypoint;

export type GoAroundWaypoint = {
  type: "go around";
};

export type ReturnToLaunchWaypoint = {
  type: "return to launch";
};

export type CameraIntervalCaptureWaypoint = {
  type: "camera interval capture";
  distance: number;
};

export type SetAirspeedWaypoint = {
  type: "set airspeed";
  airspeed: number;
};

export type TagWaypoint = {
  type: "tag";
  tag: WaypointTag;
  value?: number;
};

export type JumpTagWaypoint = {
  type: "jump tag";
  tag: WaypointTag;
};

export enum WaypointTag {
  TAKEOFF_COMPLETE = 100,
  LANDING_START = 101,
  DETERMINE_LAND_DIRECTION = 200,
  PRIMARY_LANDING = 300,
  REVERSE_LANDING = 301,
  PRIMARY_LANDING_DESCENT = 310,
  REVERSE_LANDING_DESCENT = 311,
  CALIBRATE_ALTITUDE_LANDING = 320,
  LANDING_CALIBRATION_START = 321,
  LANDING_FINAL = 322,
  MEASURE_AGL_START = 400,
  CALIBRATE_BARO = 401,
  CUE_MISSION_COMPLETE = 900,
  CUSTOM_0 = 1000,
  CUSTOM_1 = 1001,
  CUSTOM_2 = 1002,
  CUSTOM_3 = 1003,
  CUSTOM_4 = 1004,
  CUSTOM_5 = 1005,
  CUSTOM_6 = 1006,
  CUSTOM_7 = 1007,
  CUSTOM_8 = 1008,
  CUSTOM_9 = 1009,
  TACTICAL_LANDING = 1331
}

export type PayloadControlWaypoint = {
  type: "payload control";
  relay: Relay;
  on: boolean;
};

export type Waypoint =
  | UnknownWaypoint
  | HomeWaypoint
  | SimpleWaypoint
  | TakeoffWaypoint
  | VtolTakeoffWaypoint
  | LoiterWaypoint
  | LoiterTurnsWaypoint
  | LoiterTimeWaypoint
  | LoiterToAltitudeWaypoint
  | LandStartWaypoint
  | LandWaypoint
  | VtolLandWaypoint
  | GoAroundWaypoint
  | ReturnToLaunchWaypoint
  | CameraIntervalCaptureWaypoint
  | SetAirspeedWaypoint
  | TagWaypoint
  | JumpTagWaypoint
  | PayloadControlWaypoint;

export type WaypointType = Waypoint["type"];

export type Mission = {
  waypoints: Waypoint[];
};

export type Loiter = {
  position: Position;
  radius?: number;
  counterclockwise: boolean;
};

export type MissionInfo = {
  terrain: Position[][];
  terrainCollision: Position[][];
  terrainCollisionGround: Position[][];
  noLineOfSight: Position[][];
  lowAltitude: Position[][];
};

export const AdsbVehicleTypeNames = [
  "No info",
  "Light",
  "Small",
  "Large",
  "High-vortex large",
  "Heavy",
  "High performance",
  "Rotocraft",
  "Unassigned",
  "Glider",
  "Lighter-than-air",
  "Parachute",
  "Ultralight",
  "Unassigned2",
  "UAV",
  "Space",
  "Unassigned3",
  "Emergency surface",
  "Service surface",
  "Point obstacle"
];

export type AdsbVehicleType = (typeof AdsbVehicleTypeNames)[number];

export type AdsbVehicle = {
  callsign: string;
  time: Time;
  position: Position;
  heading: number;
  horizontalVelocity: number;
  verticalVelocity: number;
  type: AdsbVehicleType;
  secondsSinceLastComm: number;
  squawkCode: number;
};

export type Vessel = {
  mmsi: number;
  imo: number;
  name: string;
  type: string;
  time: Time;
  position: Position;
  heading: number;
};

export type SwarmVehicle = {
  id: number;
  squadronId: number;
  time: Time;
  position: Position;
  radius: number;
  state: "ready" | "active";
};

export type Photo = {
  position: Position;
  orientation: Orientation;
};

export type Inspect =
  | { type: "terrain"; position: Position }
  | { type: "mission"; position: Position; mission: Mission }
  | { type: "waypoint"; position: Position; waypoint: Waypoint }
  | { type: "flight path"; position: Position }
  | { type: "target"; position: Position }
  | { type: "adsb"; position: Position; vehicle: AdsbVehicle }
  | { type: "tip"; position: Position; tip: Tip }
  | { type: "ground-station"; position: Position }
  | { type: "vessel"; position: Position; vessel: Vessel }
  | { type: "cot"; position: Position; event: CotEvent };

export type Follow =
  | { type: "cot"; uid: string }
  | { type: "vessel"; mmsi: number };

export type GroundStation = {
  id: number;
  state: GroundStationState;
  close: () => void;
};

export type CursorOnTarget = {
  send: (event: CotEvent) => Promise<void>;
  receive: (handler: (event: CotEvent) => void) => { unsubscribe: () => void };
  close: () => void;
};

export type Snapshot = {
  time: number;
  states: State[];
  flightPaths: Position[][];
  cotEvents: CotEvent[];
  vessels: Vessel[];
};

export type History = {
  timeRange: [number, number] | undefined;
  snapshot: (time: Time) => Snapshot;
  recordState: (state: State) => void;
  recordCotEvent: (cotEvent: CotEvent) => void;
  recordVessels: (vessel: Vessel[]) => void;
};

export type GeoImage = {
  geoImageId: string; // warning this is public facing (file name for downloads)
  position: Position;
  thumbnail: {
    content: Base64;
    mime: string;
  };
  original?: {
    url: string;
    mime: string;
    width: number;
    height: number;
    size: number;
  };
};

export type Tip = {
  uid: string;
  position: Position;
  ellipse?: {
    major: number;
    minor: number;
    angle: number;
  };
  sewsi?: Sewsi;
};

export type Sewsi = {
  color: string;
  name: string;
  frequency: number;
  rssi: number;
  bearing: number;
  priority: number;
  comments: string;
};
